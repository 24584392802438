import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Paper,
  Typography,
  Box,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { PhotoCamera } from '@mui/icons-material';
import { useCreatorAuth } from '../../../Hooks/creator-use-auth'; // Correct import for the useCreatorAuth hook
import API from '../../../API';
import AlertDialog from "../../../Components/AlertDialog";
import useAlertDialog from "../../../Components/useAlertDialog";
import ConsentForm from "../popups/consentform";
import { useGoogleLogin } from "@react-oauth/google";
import UploadFileDialogPFP from "../../onboardupload";
import MuiPhoneInput from "../../../Components/MuiPhoneInput";
import { parseLocalFloat, parseLocalInt } from "../../../Utils/constants";
import { RateField } from "../../../Components/RateField";

const EditCreator = () => {
  const { creatorToken } = useCreatorAuth(); // Get the creatorToken from the context
  const username = creatorToken?.creator_user?.username;

  const { dialogState, openDialog, closeDialog } = useAlertDialog();

  const [contactEmail, setContactEmail] = useState('');
  const [paymentEmail, setPaymentEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [creatorName, setCreatorName] = useState('');
  const [creatorRegion, setCreatorRegion] = useState('');
  const [platforms, setPlatforms] = useState({
    TikTok: false,
    Instagram: false,
    YouTube: false,
  });
  const [primaryMarket, setPrimaryMarket] = useState('');
  const [notesContentStyle, setNotesContentStyle] = useState('');
  const [profilePhotoUrl, setProfilePhotoUrl] = useState('');
  const [race, setRace] = useState('');
  const [gender, setGender] = useState('');
  const [location, setLocation] = useState('');

  const [instagramLink, setInstagramLink] = useState("");
  const [instagramBrandRate, setInstagramBrandRate] = useState('');
  const [instagramFollowerCount, setInstagramFollowerCount] = useState('');
  const [instagramSongRate, setInstagramSongRate] = useState('');
  const [instagramStoryRate, setInstagramStoryRate] = useState('');

  const [tikTokLink, setTikTokLink] = useState("");
  const [tikTokFollowerCount, setTikTokFollowerCount] = useState('');
  const [tikTokBrandRate, setTikTokBrandRate] = useState('');
  const [tikTokSongRate, setTikTokSongRate] = useState('');

  const [youtubeLink, setYoutubeLink] = useState("");
  const [youtube30sBrandRate, setYoutube30sBrandRate] = useState('');
  const [youtubeFollowerCount, setYoutubeFollowerCount] = useState('');
  const [youtube60sBrandRate, setYoutube60sBrandRate] = useState('');
  const [youtubeShortsBrandRate, setYoutubeShortsBrandRate] = useState('');

  const [userStatus, setUserStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("PayPal");
  const [isStripeNew, setIsStripeNew] = useState(true);
  const [stripeUserId, setStripeUserId] = useState("");
  const [openConsent, setOpenConsent] = useState(false);
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [processEmails, setProcessEmails] = useState(true)

  const scope = ['https://mail.google.com/', 'profile', 'email'];

  const scopeString = scope.reduce((accum, currSco, currIdx) => (accum + (currIdx === 0 ? '' : ' ') + currSco), '');
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      closeDialog();
      await handleSaveCreator(tokenResponse.code);
    },
    onError: errorResponse => { openDialog("Google Error", `Error getting Google Authorization: ${errorResponse}`, closeDialog, closeDialog, "Ok", null); },
    onNonOAuthError: errorResponse => { openDialog("Google Error", `Error getting Google Authorization: ${errorResponse}`, closeDialog, closeDialog, "Ok", null); }, 
    scope: scopeString,
    flow: "auth-code"
  });

  const userData = useRef(null);

  async function GetUserData() {
    try {
      setIsLoading(true);
      const response = await API.creatorConnect.getCreator();
      const user = response.user;
      userData.current = response.user;

      const creator = response.user.creator;

      setContactEmail(creator.email);
      setPaymentEmail(user.email);
      setPhoneNumber(creator.phone_number);
      setCreatorName(creator.creator);
      setCreatorRegion(creator.region);
      setPlatforms({
        TikTok: creator.tiktok_link !== null,
        Instagram: creator.instagram_link !== null,
        YouTube: creator.youtube_link !== null,
      });
      setPrimaryMarket(creator.primary_market);
      setNotesContentStyle(creator.notes_content_style);
      setRace(creator.ethnicity);
      setGender(creator.gender);
      setLocation(creator.geolocation);
      setProfilePhotoUrl(creator.pfphref);

      setInstagramLink(creator.instagram_link ? creator.instagram_link.replace("https://www.instagram.com/", "") : "");
      setInstagramFollowerCount(creator.instagram ? formatNumber(creator.instagram) : "");

      setInstagramBrandRate(creator.rate_ig_reelbrand?.toFixed(2) || "");
      setInstagramSongRate(creator.rate_ig_reelsound?.toFixed(2) || "");
      setInstagramStoryRate(creator.rate_ig_feedpost?.toFixed(2) || "");

      setTikTokLink(creator.tiktok_link ? creator.tiktok_link.replace("https://www.tiktok.com/@", "") : "");
      setTikTokFollowerCount(creator.tiktok ? formatNumber(creator.tiktok) : "");

      setTikTokBrandRate(creator.rate_tt_brand?.toFixed(2) || "");
      setTikTokSongRate(creator.rate_tt_sound?.toFixed(2) || "");

      setYoutubeLink(creator.youtube_link ? creator.youtube_link.replace("https://www.youtube.com/", "") : "");
      setYoutubeFollowerCount(creator.youtube ? formatNumber(creator.youtube) : "");

      setYoutube30sBrandRate(creator.rate_yt_integ30s?.toFixed(2) || "");
      setYoutube60sBrandRate(creator.rate_yt_integ60s?.toFixed(2) || "");
      setYoutubeShortsBrandRate(creator.rate_yt_short?.toFixed(2) || "");

      setUserStatus(creator.status ? creator.status : (user.refresh_token ? 'Partner' : 'Associate'));

      setPaymentMethod(user.payout_preferred);
      setProcessEmails(user.process_emails);
      setIsStripeNew(user.stripe_id === null || user.stripe_id.length === 0);

      setIsLoading(false);

    } catch (error) {
      openDialog("Error", `Network error: ${(error.response && error.response.data.error) ? error.response.data.error : error.message}\n Try refreshing the page.`, closeDialog, closeDialog, "Ok", null);
      console.error('Network error:', error);
    }
  }

  useEffect(() => {
    GetUserData();
  }, []);

  const handleOpenUploadDialog = () => {
    setIsUploadDialogOpen(true);
  };

  const handleCloseUploadDialog = (uploadedUrl) => {
    if (uploadedUrl) {
      setProfilePhotoUrl(uploadedUrl);
    }
    setIsUploadDialogOpen(false);
  };

  const handlePaymentMethodChange = (event) => {
    const method = event.target.value;
    setPaymentMethod(method);
    sessionStorage.setItem("paymentMethod", method);
    sessionStorage.setItem("creatorName", creatorName);
  };

  const goToStripeId = () => {
    const redirectUri = `https://blitzpay.pro/creatorconnect/start/stripe`;
    window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${encodeURIComponent(redirectUri)}`;
  };

  const handlePlatformChange = (event) => {
    setPlatforms({ ...platforms, [event.target.name]: event.target.checked });
  };

  const handleFollowerCountChange = (setter) => (e) => {
    const rawValue = e.target.value.replace(/[^0-9]/g, "");
    const formattedValue = formatNumber(rawValue);
    setter(formattedValue);
  };

  const formatNumber = (value) => {
    const number = parseInt(value, 10);
    return isNaN(number) ? "0" : number.toString();
  };

  const formatRate = (value) => {
    return isNaN(number) ? "0" : number.toFixed(2);
  };

  const handleStartSaveCreator = () => {
    if (phoneNumber.length < 11) {
      alert(`This Phone number is not valid`);
      return;
    }

    if (creatorName.includes(" ")) {
      setValidationError("Creator name cannot contain spaces.");
      return;
    }

    const anyPlatform = [platforms.TikTok, platforms.Instagram, platforms.YouTube].some((value) => value);
    if (!anyPlatform) {
      alert('You need to select at least one platform.');
      return;
    }

    if (platforms.TikTok) {
      const anyPromo = [tikTokBrandRate, tikTokSongRate].some((value) => value);
      if (!anyPromo) {
        alert('You need to select at least one promotion type for TikTok.');
        return;
      }
    }

    if (platforms.Instagram) {
      const anyPromo = [instagramBrandRate, instagramSongRate, instagramStoryRate].some((value) => value);
      if (!anyPromo) {
        alert('You need to select at least one promotion type for Instagram.');
        return;
      }
    }

    if (platforms.YouTube) {
      const anyPromo = [youtube30sBrandRate, youtube60sBrandRate, youtubeShortsBrandRate].some((value) => value);
      if (!anyPromo) {
        alert('You need to select at least one promotion type for YouTube.');
        return;
      }
    }


    if (userStatus === 'Partner' && !userData.current.refresh_token) {
      openDialog("Changing Partnership Status", "A Google pop-up will now open up for you to connect your Gmail account and become a Blitz Partner.", googleLogin, googleLogin, "Ok", null);
    } else {
      handleSaveCreator();
    }
  };

  const handleSaveCreator = async (google_code = null) => {


    const formattedTikTokLink = tikTokLink ? `https://www.tiktok.com/@${tikTokLink}` : null;
    const formattedInstagramLink = instagramLink ? `https://www.instagram.com/${instagramLink}` : null;
    const formattedYouTubeLink = youtubeLink ? `https://www.youtube.com/${youtubeLink}` : null;

    let payload = null

    try {
      payload = {
        tiktok: parseLocalInt(tikTokFollowerCount) || null,
        tiktok_link: formattedTikTokLink || null,
        instagram: parseLocalInt(instagramFollowerCount) || null,
        instagram_link: formattedInstagramLink || null,
        youtube: parseLocalInt(youtubeFollowerCount) || null,
        youtube_link: formattedYouTubeLink || null,
        geolocation: location,
        gender: gender,
        ethnicity: race,
        primary_market: primaryMarket,
        region: creatorRegion,
        notescontent_style: notesContentStyle || null,

        rate_tt_sound: parseLocalFloat(tikTokSongRate),
        rate_tt_brand: parseLocalFloat(tikTokBrandRate),
        rate_ig_feedpost: parseLocalFloat(instagramStoryRate),
        rate_ig_reelsound: parseLocalFloat(instagramSongRate),
        rate_ig_reelbrand: parseLocalFloat(instagramBrandRate),
        rate_yt_integ30s: parseLocalFloat(youtube30sBrandRate),
        rate_yt_integ60s: parseLocalFloat(youtube60sBrandRate),
        rate_yt_short: parseLocalFloat(youtubeShortsBrandRate),

        contactEmail: contactEmail,
        paymentEmail: paymentEmail,
        phone_number: phoneNumber,
        payment_method: paymentMethod,
        stripe_id: stripeUserId,
        pfphref: profilePhotoUrl,
        status: userStatus,
        process_emails: processEmails,
        google_code
      };
    } catch (error) {
      alert(`Error parsing this form: ${error}`);
      return;
    }


    try {
      await API.creatorConnect.editCreator(payload);
      if (paymentMethod === 'PayPal' || !isStripeNew) {
        openDialog("Success", "Creator data edited successfully", closeDialog, closeDialog, "Ok", null);
      } else {
        openDialog("Success", "Creator data edited successfully. You will now be redirected to Stripe to set up BlitzPay", goToStripeId, goToStripeId, "Ok", null);
      }
    } catch (error) {
      openDialog("Error", `Network error: ${error.response.data && error.response.data.error ? error.response.data.error : error}`, closeDialog, closeDialog, "Ok", null);
      console.error('Network error:', error);
    }
  };

  const OnClickUpdateStripe = () => {
    openDialog("Change Payment Data", "This will redirect you without saving any changes. Click Update to be redirected to update your payment info, or Cancel to continue editing and save.", goToStripeId, closeDialog, "Update", "Cancel");
  };

  const OnSelectField = (e) => {
    if (e.target.value === 'Associate') {
      openDialog("Change Partnership", "This will delete your Google connection from our server, and we won't be managing your brand deals through our platform. Continue?", () => { setUserStatus('Associate'); closeDialog(); }, closeDialog, "Yes", "Cancel");
    } else {
      setOpenConsent(true);
    }
  };

  const OnConsentGoogle = (consented) => {
    setOpenConsent(false);
    if (!consented) {
      setUserStatus('Associate');
    } else {
      setUserStatus('Partner');
    }
  };

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <AlertDialog alertState={dialogState}></AlertDialog>
      <ConsentForm open={openConsent} onClose={OnConsentGoogle}></ConsentForm>
      <Paper elevation={1} sx={{ paddingInline: 4, paddingBlockStart: 2, paddingBlockEnd: 4, marginBlockStart: 2, maxWidth: '50em', marginInline: "auto" }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
          <Avatar
            alt={creatorName}
            src={profilePhotoUrl}
            sx={{ width: 100, height: 100, cursor: 'pointer' }}
            onClick={handleOpenUploadDialog}
          >
            {!profilePhotoUrl && <PhotoCamera fontSize="large" />}
          </Avatar>
        </Box>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleStartSaveCreator();
          }}
        >
          <Typography variant="h5" sx={{ marginBlockEnd: 2, marginBlockStart: 1 }}>Creator Connect data</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: "end" }}>
            <FormControl fullWidth sx={{ flex: 1 }} margin="dense">
              <InputLabel>Payment Method</InputLabel>
              <Select value={paymentMethod} onChange={handlePaymentMethodChange}>
                <MenuItem value="PayPal">PayPal, Standard, Fees Apply</MenuItem>
                <MenuItem value="Stripe">BlitzPay, Faster, Less Fees</MenuItem>
              </Select>
            </FormControl>
            {paymentMethod === "PayPal" && <TextField
              margin="dense"
              label="Paypal Payment Email"
              type="email"
              fullWidth
              sx={{ flex: 1 }}
              value={paymentEmail}
              onChange={(e) => setPaymentEmail(e.target.value)}
              variant="outlined"
              required
            />}
            {paymentMethod === "Stripe" && <Button
              variant="contained"
              color="secondary"
              sx={{ flex: 1, margin: 0 }}
              onClick={OnClickUpdateStripe}
            >
              {isStripeNew ? "Add Payment Information" : "Update Payment Information"}
            </Button>}
          </Box>
          <FormControl fullWidth margin="dense">
            <InputLabel>Status</InputLabel>
            <Select value={userStatus} onChange={OnSelectField}>
              <MenuItem value="Associate">Associate</MenuItem>
              <MenuItem value="Partner">Partner</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel 
            checked={processEmails} 
            onChange={()=>setProcessEmails((lastValue)=>!lastValue)} 
            control={<Checkbox></Checkbox>}
            label='Enable Auto-emailer'
          ></FormControlLabel>
          <Typography variant="h5" sx={{ marginBlockEnd: 2, marginBlockStart: 1 }}>Creator information</Typography>
          <TextField
            margin="dense"
            label="Creator Name"
            type="text"
            fullWidth
            value={creatorName}
            onChange={(e) => setCreatorName(e.target.value)}
            variant="outlined"
            required
            error={!!validationError}
            helperText={validationError}
            disabled
          />
          <FormControl variant="outlined" fullWidth margin="dense" required>
            <InputLabel>Creator Region</InputLabel>
            <Select
              label="Creator Region"
              value={creatorRegion}
              onChange={(e) => setCreatorRegion(e.target.value)}
            >
              <MenuItem value="NACAUKAUS">USA, Canada, UK, Australia</MenuItem>
              <MenuItem value="Europe">Europe</MenuItem>
              <MenuItem value="Asia">Asia</MenuItem>
              <MenuItem value="LATAM">LATAM</MenuItem>
              <MenuItem value="Africa">Africa</MenuItem>
            </Select>
          </FormControl>
          <FormGroup row>
            {Object.keys(platforms).map((platform) => (
              <FormControlLabel
                key={platform}
                control={
                  <Checkbox
                    checked={platforms[platform]}
                    onChange={handlePlatformChange}
                    name={platform}
                  />
                }
                label={platform}
              />
            ))}
          </FormGroup>
          {platforms.Instagram && (
            <>
              <TextField
                margin="dense"
                label="Instagram Username"
                type="text"
                fullWidth
                value={instagramLink}
                onChange={(e) => setInstagramLink(e.target.value)}
                variant="outlined"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      instagram.com/
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                margin="dense"
                label="Instagram Follower Count"
                type="text"
                fullWidth
                value={instagramFollowerCount}
                onChange={handleFollowerCountChange(setInstagramFollowerCount)}
                variant="outlined"
                required
              />
              <RateField
                margin="dense"
                label="Instagram Brand Rate"
                fullWidth
                value={instagramBrandRate}
                onChange={(value) => setInstagramBrandRate(value)}
                variant="outlined"
                minValue={100}
              />
              <RateField
                margin="dense"
                label="Instagram Song Rate"
                fullWidth
                value={instagramSongRate}
                onChange={(value) => setInstagramSongRate(value)}
                variant="outlined"
                minValue={100}
              />
              <RateField
                margin="dense"
                label="Instagram Story Rate"
                fullWidth
                value={instagramStoryRate}
                onChange={(value) => setInstagramStoryRate(value)}
                variant="outlined"
                minValue={20}
              />
            </>
          )}
          {platforms.TikTok && (
            <>
              <TextField
                margin="dense"
                label="TikTok Username"
                type="text"
                fullWidth
                value={tikTokLink}
                onChange={(e) => setTikTokLink(e.target.value)}
                variant="outlined"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      tiktok.com/@
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                margin="dense"
                label="TikTok Follower Count"
                type='number'
                fullWidth
                value={tikTokFollowerCount}
                onChange={handleFollowerCountChange(setTikTokFollowerCount)}
                variant="outlined"
                required
              />
              <RateField
                margin="dense"
                label="TikTok Brand Rate"
                fullWidth
                value={tikTokBrandRate}
                onChange={(value) => setTikTokBrandRate(value)}
                variant="outlined"
                minValue={100}
              />
              <RateField
                margin="dense"
                label="TikTok Song Rate"
                fullWidth
                value={tikTokSongRate}
                onChange={(value) => setTikTokSongRate(value)}
                variant="outlined"
                minValue={100}
              />
            </>
          )}
          {platforms.YouTube && (
            <>
              <TextField
                margin="dense"
                label="Youtube Username"
                type="text"
                fullWidth
                value={youtubeLink}
                onChange={(e) => setYoutubeLink(e.target.value)}
                variant="outlined"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      youtube.com/
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                margin="dense"
                label="YouTube Follower Count"
                type='number'
                fullWidth
                value={youtubeFollowerCount}
                onChange={handleFollowerCountChange(setYoutubeFollowerCount)}
                variant="outlined"
                required
              />
              <RateField
                margin="dense"
                label="YouTube Brand Rate - 30s"
                fullWidth
                value={youtube30sBrandRate}
                onChange={(value) => setYoutube30sBrandRate(value)}
                variant="outlined"
                minValue={100}
              />
              <RateField
                margin="dense"
                label="YouTube Brand Rate - 60s"
                fullWidth
                value={youtube60sBrandRate}
                onChange={(value) => setYoutube60sBrandRate(value)}
                variant="outlined"
                minValue={100}
              />
              <RateField
                margin="dense"
                label="YouTube Shorts Rate"
                fullWidth
                value={youtubeShortsBrandRate}
                onChange={(value) => setYoutubeShortsBrandRate(value)}
                variant="outlined"
                minValue={100}
              />
            </>
          )}

          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel id="race-select-label">Race (optional)</InputLabel>
            <Select
              labelId="race-select-label"
              value={race}
              onChange={(e) => setRace(e.target.value)}
              label="Race (optional)"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Asian">Asian</MenuItem>
              <MenuItem value="Black">Black</MenuItem>
              <MenuItem value="Hispanic">Hispanic</MenuItem>
              <MenuItem value="White">White</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" fullWidth margin="dense" required>
            <InputLabel id="gender-select-label">Gender</InputLabel>
            <Select
              labelId="gender-select-label"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              label="Gender"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Non-binary">Non-binary</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Location"
            type="text"
            fullWidth
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            variant="outlined"
            required
          />
          <FormControl variant="outlined" fullWidth margin="dense" required>
            <InputLabel id="primary-market-label">Primary Market</InputLabel>
            <Select
              labelId="primary-market-label"
              value={primaryMarket}
              onChange={(e) => setPrimaryMarket(e.target.value)}
              label="Primary Market"
            >
              {[
                "Activist",
                "Artist",
                "Beauty",
                "Cars",
                "Cosplay",
                "Comedy",
                "Country",
                "Dance",
                "Educational",
                "Fashion",
                "Fitness",
                "Food",
                "Gaming",
                "Lifestyle",
                "Music",
                "Pets",
                "Reviews",
                "Sports",
                "Tech",
                "Thirst Trap",
                "Travel",
              ].map((market) => (
                <MenuItem key={market} value={market}>
                  {market}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Bio"
            type="text"
            fullWidth
            multiline
            rows={4}
            placeholder="Tell us about yourself"
            variant="outlined"
            value={notesContentStyle}
            onChange={(e) => setNotesContentStyle(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Contact Email"
            type="email"
            fullWidth
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
            variant="outlined"
            required
          />
          <MuiPhoneInput
            country={'us'}
            preferredCountries={['us']}
            value={phoneNumber}
            onChange={setPhoneNumber}
            isValid={(value) => value.length >= 10}
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ marginTop: 1, marginBottom: 2 }}
          >
            Save Changes
          </Button>
        </form>
      </Paper>
      <UploadFileDialogPFP open={isUploadDialogOpen} onClose={handleCloseUploadDialog} />
    </>
  );
};

export default EditCreator;
