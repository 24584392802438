import React, { useMemo } from "react";
import { useMutation } from "react-query";
import { Grid, Card, CardMedia, CardContent, Typography, Button } from "@mui/material";
import profilePhoto from "../../../../../Components/globalAssets/ppfLogo.png"; // Placeholder for the profile photo
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../../Hooks/use-auth";
import client from "../../../../../API";

const Conversations = ({ creators = [], campaignId }) => {
  const { getCurrrentUser } = useAuth();
  const userInfo = getCurrrentUser();
  const navigate = useNavigate();

  const checkConversation = useMutation(client.conversations.check, {
    onSuccess: (data, variables) => {
      console.log("Check response data:", data);
      if (data.exists) {
        navigateToConversation(variables.creatorName);
      } else {
        createConversation.mutate(variables);
      }
    },
    onError: (error) => {
      console.error("Check response error:", error);
    },
  });

  const createConversation = useMutation(client.conversations.create, {
    onSuccess: (data, variables) => {
      console.log("Create response data:", data);
      navigateToConversation(variables.creatorName);
    },
    onError: (error) => {
      console.error("Create response error:", error);
    },
  });

  const startConversation = (creatorId, creatorName) => {
    const payload = {
      campaign_id: campaignId,
      creator_id: creatorId,
      creatorName: creatorName,
    };
    console.log("Payload for check:", payload);
    checkConversation.mutate(payload);
  };

  const navigateToConversation = (creatorName) => {
    navigate(`/campaigns/convo/${campaignId}/${creatorName}`);
  };

  const consolidatedCreators = useMemo(() => {
    const creatorMap = {};

    creators.forEach((creator) => {
      if (!creatorMap[creator.name]) {
        creatorMap[creator.name] = {
          ...creator,
          platforms: [],
          types: [],
        };
      }
      creatorMap[creator.name].platforms.push(creator.promotionPlatform);
      creatorMap[creator.name].types.push(creator.promotionType);
    });

    return Object.values(creatorMap).map((creator) => ({
      ...creator,
      platforms: creator.platforms.join(", "),
      types: creator.types.join(", "),
    }));
  }, [creators]);

  return (
    <>
      <Grid container spacing={2}>
        {consolidatedCreators.map((creator, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card>
              <CardMedia
                component="img"
                height="170"
                image={creator.pfphref || profilePhoto}
                alt={creator.name}
              />
              <CardContent>
                <Typography variant="h5">
                  <a
                    href={`https://blitzpay.pro/creators/${creator.name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    @{creator.name}
                  </a>
                </Typography>
                <Typography variant="h6">{`Price: ${creator.price}`}</Typography>
                {creator.status !== "Accepted" && (
                  <Typography color="error">
                    Creator must accept the initial campaign message to start a conversation
                  </Typography>
                )}
                {creator.status === "Accepted" && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 20 }}
                    onClick={() => startConversation(creator.id, creator.name)}
                  >
                    Start Conversation
                  </Button>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Conversations;
