import APIengagement from "../APIengagement";

export const CreatorCampaignStatus = [
  'Pitched',
  'Approved',
  'Counter',
  'Accepted',
  'Declined',
  'Dropped'
]


export const getOfferFromCampaign = (campaign, username) => {
  const creators = campaign.creators;
  if (!creators || creators.length === 0) return 'N/A';
  const creator = creators.find(c => c.id === username);
  if (!creator) return 'N/A';
  const agencyRate = parseFloat(creator.agencyRate);
  const price = parseFloat(creator.price);
  return agencyRate ? `$${agencyRate.toFixed(2)}` : price ? `$${price.toFixed(2)}` : 'N/A';
};

export const isValidVideoUrl = (url) => {
  return (
    url.includes('youtube.com') ||
    url.includes('youtu.be') ||
    url.includes('tiktok.com')
  );
  

};

export function parseLocalInt(numberString) {
  if (!numberString)
    return null;
  // Replace commas with dots
  const normalizedString = numberString.replace(/,/g, '');

  const result = parseInt(normalizedString, 10);
  // Check if the result is NaN and throw an error
  if (isNaN(result)) {
    throw new Error(`Invalid number format: ${numberString}`);
  }

  return result;
}

export function parseLocalFloat(numberString) {
  if (!numberString)
    return null;
  // Replace commas with dots
  const normalizedString = numberString.replace(',', '.');

  const result = parseFloat(normalizedString);
  // Check if the result is NaN and throw an error
  if (isNaN(result)) {
    throw new Error(`Invalid number format: ${numberString}`);
  }

  return result;
}

// this function returns a promise that resolves after n milliseconds
export const wait = (n) => new Promise((resolve) => setTimeout(resolve, n));

export async function TrackLinks(creators = []) {
  const links = {}

  for (const creator of creators) {
    if (creator.liveLink) {
      try {
        const engagement = (await APIengagement.engagement.get_engagement({ urls: [creator.liveLink] }))[0];
        if (engagement.error)
          throw new Error(`url: ${engagement.url} error: ${engagement.error}`);
        links[creator.id] = engagement;
      } catch (error) {
        console.log(error);
        links[creator.id] = { error };
      }
    } else {
      links[creator.id] = null;
      console.log(`url: ${creator.id} error: no url`);
    }
  }

  return links;
}

export const drawerWidth = "20rem";

const emptyCountry = { code: 'N/A', value: Number.NEGATIVE_INFINITY };
const emptyAge = { age_range: 'N/A', value: Number.NEGATIVE_INFINITY };
const emptyGender = { name: 'N/A', value: Number.NEGATIVE_INFINITY };

export const CountSets = (sets = []) => (
  sets.reduce((counter, value) => counter + value.size, 0)
)

export const ToggleSet = (value, setGetter, setSetter) => {
  if (setGetter.has(value))
    setSetter(prevSet => {
      prevSet.delete(value)
      return new Set(prevSet)
    })
  else
    setSetter(prevSet => new Set(prevSet.add(value)))
}

export const highestValueCountry = (publicData) => {
  return publicData.reduce((ctrMax, country) => {
    if (country.value > ctrMax.value)
      ctrMax = country;
    return ctrMax;
  }, emptyCountry);
}

export const higherValueAge = (publicData) => {
  return publicData.reduce((ageMax, ageGroup) => {
    if (ageGroup.value > ageMax.value)
      ageMax = ageGroup;
    return ageMax;
  }, emptyAge);
}

export const higherValueGender = (publicData) => {
  return publicData.reduce((genderMax, genderGroup) => {
    if (genderGroup.value > genderMax.value)
      genderMax = genderGroup;
    return genderMax;
  }, emptyGender);
}

export const getAgeDemos = (demo) => {
  return demo.reduce((acc, entry) => {
    const { age_range, value } = entry;
    const existingEntry = acc.find(item => item.age_range === age_range);

    if (existingEntry) {
      existingEntry.value += Math.round(value);
    } else {
      acc.push({ age_range: age_range, value: Math.round(value) });
    }

    return acc;
  }, [])
}

export const getGenderDemos = (demo) => ([
  {
    gender: 'Male',
    value: demo.reduce((accum, item) => {
      if (item.gender.toLowerCase() === 'male') accum += Math.round(item.value);
      return accum;
    }, 0)
  },
  {
    gender: 'Female',
    value: demo.reduce((accum, item) => {
      if (item.gender.toLowerCase() === 'female') accum += Math.round(item.value);
      return accum;
    }, 0)
  }
]);

export function generateRainbowColors(x) {
  const colors = [];
  for (let i = 0; i < x; i++) {
    const hue = Math.floor((360 / x) * i); // Calculate the hue for each color
    const color = `hsl(${hue}, 80%, 70%)`; // Use HSL to create the color
    colors.push(color);
  }
  return colors;
}

export const TimelineStatus = [
  'incomplete',
  'in progress',
  'complete'
]

export const formatCampaignSum = (sum) => {
  const numericSum = typeof sum === "number" ? sum : parseFloat(sum.replace(/[^\d.]/g, ""));
  return `$${numericSum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export const formatProposalDate = (dateString) => {
  const date = new Date(dateString);
  return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
    .getDate()
    .toString()
    .padStart(2, "0")}/${date.getFullYear().toString().substr(-2)}`;
};

//As used by insightiq
export const ageGroups = ["13-17", "18-24", "25-34", "35-44", "45-64", "65+"];

export const region = ["NACAUKAUS", "Europe", "Asia", "LATAM", "Africa"];

export const ethnicity = ["Asian", "Black", "Hispanic", "White", "Other"];

export const genders = ["Male", "Female", "Non-binary", "Other"];

export const niches = [
  "Activist",
  "Artist",
  "Beauty",
  "Cars",
  "Cosplay",
  "Comedy",
  "Country",
  "Dance",
  "Educational",
  "Fashion",
  "Fitness",
  "Food",
  "Gaming",
  "Lifestyle",
  "Music",
  "Pets",
  "Reviews",
  "Sports",
  "Tech",
  "Thirst Trap",
  "Travel",
]

export const platforms = [
  'TikTok',
  'Instagram',
  'Youtube',
  'Podcasts',  // New Platform
  'Twitch',    // New Platform (Streaming)
  'Kick',      // New Platform (Streaming)
  'X',         // New Platform (Others)
];

export const promotionTypeArray = [
  ["Sound", "Brand", "Livestream"], // TikTok
  ["Sound", "Brand", "Feed Post"],  // Instagram
  ["3045s Integration", "60s Integration", "Shorts"], // Youtube
  ["15s", "60s"],  // Podcasts (Shows)
  ["Live 30s"],    // Twitch (Streaming)
  ["Live 30s"],    // Kick (Streaming)
  ["Feed Post", "Repost"],  // X (Others)
];

export const headerToKeyMap = {
  Creator: "creator",
  TikTok: "tiktok",
  Instagram: "instagram",
  Youtube: "youtube",
  "Geolocation/Gender/Ethnicity": "geolocation_gender_ethnicity",
  "Primary Market": "primary_market",
  "Content Style": "notes_content_style",
  "TikTok Sound": "rate_tt_sound",
  "TikTok Brand": "rate_tt_brand",
  "IG Reels Sound": "rate_ig_reelsound",
  "IG Reels Brand": "rate_ig_reelbrand",
  "IG Feed Post": "rate_ig_feedpost",
  "Instagram Link": "instagram_link",
  "TikTok Link": "tiktok_link",
  "Youtube Link": "youtube_link",
  "AVG Views": "avg_views",
  "3045s Integration": "rate_yt_integ30s",
  "60s Integration": "rate_yt_integ60s",
  "shorts": "rate_yt_short"
};

export const headers = {
  TikTok: [
    "Creator",
    "TikTok",
    "Geolocation/ Gender/ Ethnicity",
    "Primary Market",
    "Content Style",
    "AVG Views",
  ],
  Instagram: [
    "Creator",
    "Instagram",
    "Geolocation/ Gender/ Ethnicity",
    "Primary Market",
    "Content Style",
    "Instagram Link",
    "AVG Views",
  ],
  Youtube: [
    "Creator",
    "Geolocation/ Gender/ Ethnicity",
    "Primary Market",
    "Content Style",
    "AVG Views",
  ],
  Podcasts: [
    "Creator",
    "Geolocation/ Gender/ Ethnicity",
    "Primary Market",
    "Content Style",
    "AVG Views",
  ],  // Shows
  Twitch: [
    "Creator",
    "Geolocation/ Gender/ Ethnicity",
    "Primary Market",
    "Content Style",
    "AVG Views",
  ],      // Streaming
  Kick: [
    "Creator",
    "Geolocation/ Gender/ Ethnicity",
    "Primary Market",
    "Content Style",
    "AVG Views",
  ],        // Streaming
  X: [
    "Creator",
    "Geolocation/ Gender/ Ethnicity",
    "Primary Market",
    "Content Style",
    "AVG Views",
  ], // Others
};

export const platformToKey = {
  TikTok: ["tiktok", "rate_tt_sound", "rate_tt_brand"],
  Instagram: ["instagram", "rate_ig_reelsound", "rate_ig_reelbrand"],
  Youtube: ["youtube", "rate_yt_integ30s", "rate_yt_integ60s", "rate_yt_short"],
};

export const platPromoToKey = {
  // Existing platforms
  TikTok: { "Sound": "rate_tt_sound", "Brand": "rate_tt_brand" },
  Instagram: { "Feed Post": "rate_ig_feedpost", "Sound": "rate_ig_reelsound", "Brand": "rate_ig_reelbrand" },
  Youtube: { "3045s Integration": "rate_yt_integ30s", "60s Integration": "rate_yt_integ60s", "Shorts": "rate_yt_short" },

  // New platforms and promotions
  Podcasts: { "15s": "rate_podcast_15s", "60s": "rate_podcast_60s" },  // Shows category
  Twitch: { "Live 30s": "rate_twitch_live_30s" },  // Streaming category
  Kick: { "Live 30s": "rate_kick_live_30s" },  // Streaming category
  X: { "Feed Post": "rate_x_feedpost", "Repost": "rate_x_repost" },  // Others category
};

export const platPromoToHead = {
  TikTok: {
    "Sound": "TikTok Sound",
    "Brand": "TikTok Brand"
  },
  Instagram: {
    "Sound": "IG Reels Sound",
    "Brand": "IG Reels Brand",
    "Feed Post": "IG Feed Post"
  },
  Youtube: {
    "3045s Integration": "3045s Integration",
    "60s Integration": "60s Integration",
    "Shorts": "shorts"
  },
  Podcasts: {
    "15s": "Podcast 15-Second",
    "60s": "Podcast 60-Second"
  },
  Twitch: {
    "Live 30s": "Twitch Live 30-Second"
  },
  Kick: {
    "Live 30s": "Kick Live 30-Second"
  },
  X: {
    "Feed Post": "X Feed Post",
    "Repost": "X Repost"
  }
};

// Updated indexToPromoArray to include new platform entries
export const indexToPromoArray = [
  [0, 0], // TikTok Sound
  [0, 1], // TikTok Brand
  [1, 0], // Instagram Sound
  [1, 1], // Instagram Brand
  [1, 2], // Instagram Feed Post
  [2, 0], // Youtube 30s Integration
  [2, 1], // Youtube 60s Integration
  [2, 2], // Youtube Shorts
  [3, 0], // Podcasts 15s
  [3, 1], // Podcasts 60s
  [4, 0], // Twitch Live 30s
  [5, 0], // Kick Live 30s
  [6, 0], // X Feed Post
  [6, 1], // X Repost
];

// Function to map index to platform and promotion type
export const indexToPromoFilter = (index) => ({
  platform: platforms[indexToPromoArray[index][0]],
  promo: promotionTypeArray[indexToPromoArray[index][0]][indexToPromoArray[index][1]]
});
