import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Container,
  Paper,
  Backdrop,
  CircularProgress,
  Snackbar,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useMutation } from 'react-query';
import client from '../../API';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function getTimeString() {
  const now = new Date();
  return now.getUTCFullYear().toString() +
         (now.getUTCMonth() + 1).toString().padStart(2, '0') +
         now.getUTCDate().toString().padStart(2, '0') +
         now.getUTCHours().toString().padStart(2, '0') +
         now.getUTCMinutes().toString().padStart(2, '0') +
         now.getUTCSeconds().toString().padStart(2, '0');
}

const PayPage = () => {
  const [step, setStep] = useState(0);
  const [creators, setCreators] = useState([]);
  const [batchPayouts, setBatchPayouts] = useState([
    { paymentAmount: '', selectedCreator: null, bypassSMSVerification: false, poNumber: '' },
  ]);
  const [emailRecipients, setEmailRecipients] = useState([]);
  const [notes, setNotes] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [companyData, setCompanyData] = useState({
    balance: "Loading...",
    credit_line: "Loading...",
  });
  const [companyUsers, setCompanyUsers] = useState([]); // For email suggestions
  const [errorOpen, setErrorOpen] = useState(false); // Snackbar for error handling
  const [feePayer, setFeePayer] = useState('creator'); // New state for selecting who pays the Blitz Pay fee

  const { mutate: fetchCreatorsData } = useMutation(() => client.creators.list({ is_vendor: true }), {
    onSuccess: (data) => {
      const formattedData = data.map((creator) => ({
        ...creator,
        label: creator.creator, // Assuming 'creator' field is the name of the creator
      }));
      setCreators(formattedData);
    },
    onError: (error) => {
      console.error("Error fetching creators:", error);
    },
  });

  const { mutate: fetchCompanyData } = useMutation(client.companies.listFetch, {
    onSuccess: (data) => {
      if (data && data.balance !== undefined && data.credit_line !== undefined) {
        setCompanyData({
          balance: parseFloat(data.balance).toFixed(2),
          credit_line: data.credit_line
            ? parseFloat(data.credit_line).toFixed(2)
            : "No Credit Line Established",
        });
      } else {
        console.error("Received data is not in the expected format:", data);
      }
    },
    onError: (error) => {
      console.error("Error fetching company data:", error);
      setCompanyData({
        balance: "Error loading balance",
        credit_line: "Error loading credit line",
      });
    },
  });

  const { mutate: fetchCompanyUsers } = useMutation(() => client.users.userAdminFetch({ company_name: companyData.company_name }), {
    onSuccess: (data) => {
      setCompanyUsers(data.map(user => user.email));
    },
    onError: (error) => {
      console.error("Error fetching company users:", error);
    },
  });

  const { mutate: createBatchPayout } = useMutation(client.payouts.createBatch, {
    onSuccess: () => {
      window.location.href = "/blitzpay";
    },
    onError: (error) => {
      console.error("Error during batch payout creation:", error);
      if (error.response && error.response.status === 500) {
        setErrorOpen(true);
      }
    },
    onSettled: () => setIsProcessing(false),
  });

  useEffect(() => {
    fetchCreatorsData();
    fetchCompanyData();
    fetchCompanyUsers(); // Fetch users from the same company
  }, []);

  const handleNextStep = () => {
    if (step === 0) {
      // Validate all required fields
      for (const payout of batchPayouts) {
        if (!payout.selectedCreator) {
          alert("Please select a creator for each payout.");
          return;
        }
        if (!payout.paymentAmount) {
          alert("Please enter a payment amount for each payout.");
          return;
        }
      }
      // Move to step 2, where the user selects who pays the Blitz Pay fee
      setStep(1);
    } else if (step === 1) {
      // Move to step 3, where PO numbers and notes can be added
      setStep(2);
    } else if (step === 2) {
      if (emailRecipients.length === 0) {
        alert("Please add at least one email recipient.");
        return;
      }
      // Assign PO numbers here before submitting
      const updatedBatchPayouts = batchPayouts.map((payout) => ({
        ...payout,
        poNumber: payout.poNumber || `TCC-${getTimeString()}-${Math.floor(Math.random() * 10000)}`,
      }));
      setBatchPayouts(updatedBatchPayouts);
      handlePaymentSubmission(updatedBatchPayouts);
    }
  };

  const handlePaymentSubmission = (updatedBatchPayouts) => {
    setIsProcessing(true);
    const payload = {
      payouts: updatedBatchPayouts.map((payout) => ({
        creator_id: payout.selectedCreator?.creator,
        amount: payout.paymentAmount, // amount to be received by the creator
        bypassSMSVerification: payout.bypassSMSVerification,
        po_number: payout.poNumber,
      })),
      fee_payer: feePayer, // Include the fee_payer value in the payload
      notes,
      cc_emails: emailRecipients,  // Only pass CC emails
    };
    
    createBatchPayout(payload);
};


  const handleAddPayout = () => {
    setBatchPayouts([...batchPayouts, { paymentAmount: '', selectedCreator: null, bypassSMSVerification: false, poNumber: '' }]);
  };

  const handleRemovePayout = (index) => {
    const newBatch = [...batchPayouts];
    newBatch.splice(index, 1);
    setBatchPayouts(newBatch);
  };

  const handleBatchChange = (index, field, value) => {
    const updatedBatchPayouts = batchPayouts.map((payout, idx) =>
      idx === index ? { ...payout, [field]: value } : payout
    );
    setBatchPayouts(updatedBatchPayouts);
  };

  const handleGeneratePO = (index) => {
    const updatedBatchPayouts = batchPayouts.map((payout, idx) =>
      idx === index ? { ...payout, poNumber: `TCC-${getTimeString()}-${Math.floor(Math.random() * 10000)}` } : payout
    );
    setBatchPayouts(updatedBatchPayouts);
  };

  const calculateSubtotal = () => {
    return batchPayouts.reduce((acc, payout) => acc + parseFloat(payout.paymentAmount || 0), 0);
  };

  const calculateBlitzPayTax = (amount) => {
    return parseFloat(amount) * 0.1;
  };

  const calculateTotal = () => {
    return batchPayouts.reduce((acc, payout) => {
      if (feePayer === 'me') {
        // If client is paying, they pay the amount + 10% fee
        return acc + parseFloat(payout.paymentAmount) + calculateBlitzPayTax(payout.paymentAmount);
      } else {
        // If creator is paying, client pays exactly the amount, creator receives amount minus 10%
        return acc + parseFloat(payout.paymentAmount);
      }
    }, 0);
  };

  const calculateCreatorAmount = (amount) => {
    if (feePayer === 'me') {
      return parseFloat(amount); // Creator receives the full amount
    } else {
      return parseFloat(amount) - calculateBlitzPayTax(amount); // Creator receives amount minus 10%
    }
  };

  const handleFeePayerChange = (event) => {
    setFeePayer(event.target.value);
  };

  const handleCloseError = () => {
    setErrorOpen(false);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ margin: 2 }}>
        <Stepper activeStep={step}>
          <Step>
            <StepLabel>Select Creators</StepLabel>
          </Step>
          <Step>
            <StepLabel>Who Pays Blitz Pay Fee?</StepLabel>
          </Step>
          <Step>
            <StepLabel>Confirmation</StepLabel>
          </Step>
        </Stepper>
      </Box>

      <Paper sx={{ padding: 3, marginTop: 2 }}>
        {step === 0 ? (
          <>
            <Typography variant="h5" gutterBottom>Select Creators</Typography>
            {batchPayouts.map((payout, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Autocomplete
                  options={creators}
                  getOptionLabel={(option) => option.label || ""}
                  value={payout.selectedCreator}
                  onChange={(event, newValue) =>
                    handleBatchChange(index, "selectedCreator", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select a Creator"
                      variant="outlined"
                      required
                    />
                  )}
                />
                <TextField
                  label="Payment Amount"
                  type="number"
                  fullWidth
                  value={payout.paymentAmount}
                  onChange={(e) =>
                    handleBatchChange(index, "paymentAmount", e.target.value)
                  }
                  variant="outlined"
                  required
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={payout.bypassSMSVerification}
                      onChange={(e) => handleBatchChange(index, "bypassSMSVerification", e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Bypass SMS Verification"
                />
                {index > 0 && <Divider />}
                {index > 0 && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleRemovePayout(index)}
                    sx={{ mt: 1 }}
                  >
                    Remove Creator
                  </Button>
                )}
              </Box>
            ))}
            <Button onClick={handleAddPayout} color="primary">
              Add Another Creator
            </Button>
          </>
        ) : step === 1 ? (
          <>
            <Typography variant="h5" gutterBottom>Who Pays Blitz Pay Fee?</Typography>
            <Typography variant="body1" gutterBottom>
              Using Blitz Pay incurs a fee as we are processing your Net30 or Net60 in less than 24 hours. Choose who you want to pay that fee.
            </Typography>
            <FormControl component="fieldset">
              <FormLabel component="legend">Select who pays the Blitz Pay fee:</FormLabel>
              <RadioGroup
                aria-label="feePayer"
                name="feePayer"
                value={feePayer}
                onChange={handleFeePayerChange}
              >
                <FormControlLabel value="me" control={<Radio />} label="Me (Client)" />
                <FormControlLabel value="creator" control={<Radio />} label="Creator(s)" />
              </RadioGroup>
            </FormControl>
          </>
        ) : (
          <>
            <Typography variant="h5" gutterBottom>Confirmation</Typography>
            {batchPayouts.map((payout, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Typography>Creator: {payout.selectedCreator?.label}</Typography>
                <Typography>Amount: ${payout.paymentAmount}</Typography>
                <TextField
                  margin="dense"
                  label="PO #"
                  fullWidth
                  value={payout.poNumber}
                  onChange={(e) => handleBatchChange(index, "poNumber", e.target.value)}
                  variant="outlined"
                  required
                />
                <Button variant="outlined" onClick={() => handleGeneratePO(index)}>
                  Generate PO
                </Button>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={payout.bypassSMSVerification}
                      onChange={(e) => handleBatchChange(index, "bypassSMSVerification", e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Bypass SMS Verification"
                />
                {index > 0 && <Divider />}
              </Box>
            ))}
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">BlitzPay Tax (10%): ${calculateBlitzPayTax(calculateSubtotal())}</Typography>
              <Typography variant="h6">Creators Will Receive: ${batchPayouts.reduce((acc, payout) => acc + calculateCreatorAmount(payout.paymentAmount), 0)}</Typography>
              <Typography variant="h6">You'll Pay: ${calculateTotal()}</Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Current Credit Line: ${companyData.credit_line}</Typography>
              <Typography variant="h6">Your remaining credit line will be: ${companyData.credit_line - calculateTotal()}</Typography>
            </Box>
            <Autocomplete
              multiple
              freeSolo
              options={companyUsers}
              value={emailRecipients}
              onChange={(event, newValue) => setEmailRecipients(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  label="Email Recipients"
                  fullWidth
                  variant="outlined"
                  placeholder="Add email recipients"
                  required
                />
              )}
            />
            <TextField
              margin="dense"
              label="Notes"
              fullWidth
              multiline
              rows={4}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              variant="outlined"
            />
          </>
        )}
        <Box sx={{ textAlign: 'right', marginTop: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNextStep}
            disabled={isProcessing}
          >
            {step === 0 ? 'Next' : step === 1 ? 'Next' : 'Confirm & Pay'}
          </Button>
        </Box>
      </Paper>

      <Backdrop open={isProcessing} style={{ color: '#fff', zIndex: 1300 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error">
          Payment failed, please try again later.
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PayPage;
