import React, { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Box, Toolbar, Typography, CircularProgress, Button } from '@mui/material';
import API from '../../../API';
import BlitzHeader from '../../../Components/BlitzHeader';
import CreatorHeader from './components/CreatorHeader';
import AudienceDemographics from './components/AudienceDemographics';
import CombinedCampaignsAndReviews from './components/RatesAndCampaigns';
import CRMDialog from '../../Misc/crmComponents/crmPopup';
import BookingDialog from '../../Misc/bookingdialog';
import LeaveReview from './leaveReview';
import CampaignCreationDialog from './components/CampaignCreationDialog';
import { findMaxValue, getInfoFromCreator } from '../../../Utils';
import useAuth from '../../../Hooks/use-auth';
import styles from "./styles.module.css";
import PageTitle from '../../../Components/PageTitle';

const CreatorDetailsPage = () => {
  const { creatorId, discount } = useParams();
  const { getCurrrentUser } = useAuth();
  const [showCRMDialog, setShowCRMDialog] = useState(true);
  const [bookingDialogOpen, setBookingDialogOpen] = useState(false);
  const [reviewDialogOpen, setReviewDialogOpen] = useState(false);
  const [campaignCreationDialogOpen, setCampaignCreationDialogOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isUserFetched, setIsUserFetched] = useState(false);

  const [isFollowing, setIsFollowing] = useState(false);
  const [followerCount, setFollowerCount] = useState(0);

  const { data: response_data, error, isFetching, isError } = useQuery({
    queryKey: ['creatorDetails', creatorId],
    queryFn: () => API.creators.fetchDetails(creatorId),
    onError: (error) => console.error('Failed to fetch creator details:', error),
    refetchInterval: false,
    refetchOnWindowFocus: false
  });

  const creatorDetails = response_data?.creator || {};
  const campaigns = response_data?.campaigns || [];
  const reviews = response_data?.reviews || [];

  const { creatorInfo, followersData, promotionData } = useMemo(() => creatorDetails ? getInfoFromCreator(creatorDetails) : {}, [creatorDetails]);
  const maxValue = useMemo(() => promotionData ? findMaxValue(promotionData) : 0, [promotionData]);

  const discountPercentage = discount ? parseFloat(discount) : 0;
  const discountedPromotionData = promotionData ? promotionData.map(data => {
    data.highest.value -= (data.highest.value * discountPercentage / 100);
    data.lowest.value -= (data.lowest.value * discountPercentage / 100);
    return { ...data };
  }) : [];

  const handleBookingSubmission = async (bookingDetails) => {
    try {
      const result = await API.partnerships.bookPublic({
        name: `${bookingDetails.partnershipName} - ${bookingDetails.selectedPromotion}`,
        creator: creatorDetails.creator,
        description: bookingDetails.details,
        email: bookingDetails.email,
        proposal_date: bookingDetails.selectedDate,
        total_sum: bookingDetails.rate,
        unique_code: `promo_${creatorDetails.creator}_${new Date().getTime()}`
      });
      console.log('Booking successful:', result);
      return true;
    } catch (error) {
      console.error('Error submitting booking:', error);
      return false;
    }
  };

  const handleCloseCRMDialog = () => setShowCRMDialog(false);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getCurrrentUser();
      setCurrentUser(user);
      setIsUserFetched(true);
    };

    fetchUser();
  }, [getCurrrentUser]);

  // Fetch follower count and follow status
  useEffect(() => {
    const fetchFollowerCount = async () => {
      try {
        const followersResponse = await API.social.getFollowers(creatorId);
        const followers = followersResponse.followers || [];
        setFollowerCount(followers.length);
      } catch (error) {
        console.error('Failed to fetch followers:', error);
      }
    };
  
    // Fetch follower count when the creatorId is available
    if (creatorId) {
      fetchFollowerCount();
    }
  }, [creatorId]);
  

  

const handleFollowToggle = async () => {
  if (!currentUser) {
    // Optionally, redirect to login or show a message
    console.log('User not authenticated');
    return;
  }

  try {
    if (isFollowing) {
      // Unfollow
      await API.social.unfollow({ followee_username: creatorId });
      setIsFollowing(false);
      setFollowerCount(prevCount => prevCount - 1);
    } else {
      // Follow
      await API.social.follow({ followee_username: creatorId });
      setIsFollowing(true);
      setFollowerCount(prevCount => prevCount + 1);
    }
  } catch (error) {
    console.error('Error toggling follow status:', error);
  }
};


  if (isFetching) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress size={80} />
      </Box>
    );
  }

  if (isError || !response_data) return 'No creator details found';

  return (
    <>
      <BlitzHeader />
      <PageTitle title={`Book @${creatorId} today - Blitz`} />

      <Toolbar />
      {discount && (
        <Box className={styles.promotionalHeader} sx={{ textAlign: 'center' }}>
          <Typography variant='h4'>BOOK TODAY FOR {discount}% OFF!</Typography>
        </Box>
      )}
      <Box
        className={styles.main}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: { xs: '100%', sm: '80%', md: '60%' }
        }}
      >
        <CreatorHeader
          creatorDetails={creatorDetails}
          setBookingDialogOpen={setBookingDialogOpen}
          creatorInfo={creatorInfo}
          promotionData={discountedPromotionData}
          maxValue={maxValue}
          isFollowing={isFollowing}
          onFollowToggle={handleFollowToggle}
          followerCount={followerCount}
          isUserFetched={isUserFetched}
          currentUser={currentUser}
        />
        <AudienceDemographics creatorDetails={creatorDetails} followersData={followersData} />
        <CombinedCampaignsAndReviews campaigns={campaigns} reviews={reviews} setReviewDialogOpen={setReviewDialogOpen} />
        {showCRMDialog && (
          <CRMDialog
            isOpen={showCRMDialog}
            handleClose={handleCloseCRMDialog}
            origin={`${creatorDetails?.creator} - mediakit`}
          />
        )}
        <BookingDialog
          open={bookingDialogOpen}
          onClose={() => setBookingDialogOpen(false)}
          submitBooking={handleBookingSubmission}
          promotionData={discountedPromotionData}
        />
        <LeaveReview
          open={reviewDialogOpen}
          onClose={() => setReviewDialogOpen(false)}
          creatorId={creatorId}
        />
        {isUserFetched && currentUser && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCampaignCreationDialogOpen(true)}
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
          >
            Create a Campaign
          </Button>
        )}
        <CampaignCreationDialog
          open={campaignCreationDialogOpen}
          onClose={() => setCampaignCreationDialogOpen(false)}
          creatorId={creatorId}
        />
      </Box>
    </>
  );
};

export default CreatorDetailsPage;
