import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  TextField,
  IconButton,
  Avatar,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAuth from "../../../../../Hooks/use-auth";
import client from "../../../../../API";

//TODO -> CHANGE THE LOGIC OF MESSAGE FETCHING. USE A useQuery TO FETCH MESSAGES IF THEY EXIST, TRIGGER CREATION OF THE CONVERSATION
//        ON SENDING A MESSAGE
const CampaignCreatorSpecific = () => {
  const { campaignId, creatorName } = useParams();
  const navigate = useNavigate();
  const [creator, setCreator] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const [message, setMessage] = useState("");
  const [warningOpen, setWarningOpen] = useState(false);
  const { getCurrrentUser } = useAuth();
  const userInfo = getCurrrentUser();
  const queryClient = useQueryClient();

  // Fetch campaign data
  const {
    data: campaignData,
    isLoading: campaignLoading,
    error: campaignError,
  } = useQuery(
    ["campaign", campaignId],
    () => client.campaigns.details(campaignId),
    {
      enabled: !!campaignId,
      onSuccess: (data) => {
        setCampaign(data);
        console.log("Campaign Data:", data);
      },
    }
  );

  // Fetch creator data
  const {
    data: creatorData,
    isLoading: creatorLoading,
    error: creatorError,
  } = useQuery(
    ["creator", creatorName],
    () => client.creators.fetchDetails(creatorName),
    {
      enabled: !!creatorName,
      onSuccess: (data) => {
        setCreator(data);
        console.log("Creator Data:", data);
      },
    }
  );

  // Mutation to check if conversation exists
  const checkConversation = useMutation(client.conversations.check, {
    onSuccess: (data) => {
      console.log("Check response data:", data);
      if (data.exists) {
        setConversationId(data.conversation_id);
      } else {
        createConversation.mutate({
          campaign_id: campaignId,
          creator_id: creatorData.creator.creator, // Use numerical ID from nested creator object
        });
      }
    },
    onError: (error) => {
      console.error("Error checking conversation:", error);
    },
  });

  // Mutation to create a new conversation
  const createConversation = useMutation(client.conversations.create, {
    onSuccess: (data) => {
      console.log("Create response data:", data);
      setConversationId(data.conversation_id);
    },
    onError: (error) => {
      console.error("Error creating conversation:", error);
    },
  });

  // Check or create conversation when campaign and creator data are available
  useEffect(() => {
    if (campaignData && creatorData) {
      console.log("Campaign ID:", campaignId);
      console.log("Creator ID:", creatorData.creator.creator); // Log the correct creator ID
      checkConversation.mutate({
        campaign_id: campaignId,
        creator_id: creatorData.creator.creator, // Use numerical ID
      });
    }
  }, [campaignData, creatorData, campaignId]);

  // Fetch messages
  const {
    data: messages,
    isLoading: messagesLoading,
    error: messagesError,
    refetch: messageRefetch
  } = useQuery(
    ["messages", conversationId],
    () => client.conversations.getMessages(conversationId),
    {
      enabled: !!conversationId,
    }
  );

  // Mutation to send a message
  const sendMessageMutation = useMutation(client.conversations.sendMessage, {
    onSuccess: () => {
      queryClient.invalidateQueries(["messages", conversationId]);
      messageRefetch();
      setMessage("");
    },
    onError: (error) => {
      console.error("Error sending message:", error);
    },
  });

  const handleSendMessage = () => {
    if (!conversationId) return;

    // Check for disallowed words
    const disallowedWords = ["whatsapp", "imessage"];
    const containsDisallowedWord = disallowedWords.some((word) =>
      message.toLowerCase().includes(word)
    );

    if (containsDisallowedWord) {
      setWarningOpen(true);
      return;
    }

    if(!message)
      return;

    sendMessageMutation.mutate(
      {
        conversation_id: conversationId,
        message,
        sender_role: "user",
        user_id: userInfo.id,
      },
    );
  };

  if (campaignLoading || creatorLoading) {
    return (
      <Typography
        sx={{
          width: "100vw",
          height: "100vh",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading...
      </Typography>
    );
  }

  if (campaignError) {
    return (
      <Typography>
        Error loading campaign data: {campaignError.message}
      </Typography>
    );
  }

  if (creatorError) {
    return (
      <Typography>Error loading creator data: {creatorError.message}</Typography>
    );
  }

  return (
    <Box
      sx={{
        padding: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100vh",
      }}
    >
      {creatorData && campaignData && (
        <>
          <Box
            sx={{ position: "sticky", top: 0, zIndex: 2, paddingBottom: 2 }}
          >
            {/* Go Back Button */}
            <Button variant="outlined" onClick={() => navigate(-1)} sx={{ mb: 2 }}>
              Go Back
            </Button>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Box display="flex" alignItems="center">
                <Avatar
                  src={creatorData.creator.pfphref || "/placeholder.png"}
                  alt={creatorData.creator.name}
                />
                <Typography variant="h6" sx={{ marginLeft: 2 }}>
                  @{creatorData.creator.name} - {campaignData.name}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            sx={{ minHeight: "400px", overflowY: "auto", flexGrow: 1 }}
          >
            {messagesLoading && <Typography>Loading messages...</Typography>}
            {messagesError && (
              <Typography>Error loading messages</Typography>
            )}
            {messages &&
              messages.map((msg, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent:
                      msg.sender_id === userInfo.id ? "flex-end" : "flex-start",
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: "70%",
                      p: 2,
                      borderRadius: 2,
                      backgroundColor:
                        msg.sender_id === userInfo.id ? "#007bff" : "#e4e6eb",
                      color:
                        msg.sender_id === userInfo.id ? "white" : "black",
                      textAlign:
                        msg.sender_id === userInfo.id ? "right" : "left",
                    }}
                  >
                    <Typography variant="body1">{msg.message}</Typography>
                    <Typography
                      variant="caption"
                      sx={{ display: "block", mt: 1 }}
                    >
                      {new Date(msg.created_at).toLocaleTimeString()} -{" "}
                      {index == messages.length-1 && (msg.read_at
                        ? `Read on ${new Date(
                            msg.read_at
                          ).toLocaleString()}`
                        : "Not read yet")}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>

          <Box>
            <Box display="flex" alignItems="center">
              <TextField
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                label="Type a message"
                variant="outlined"
                sx={{ flex: 1 }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
                InputProps={{
                  style: {
                    color: "white",
                    backgroundColor: "#424242",
                  },
                }}
              />
              <IconButton
                onClick={handleSendMessage}
                color="primary"
                sx={{ ml: 2 }}
                disabled={sendMessageMutation.isLoading || !message}
              >
                {sendMessageMutation.isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <SendIcon />
                )}
              </IconButton>
            </Box>
          </Box>

          <Snackbar
            open={warningOpen}
            autoHideDuration={8000}
            onClose={() => setWarningOpen(false)}
          >
            <Alert
              onClose={() => setWarningOpen(false)}
              severity="warning"
              sx={{ width: "100%" }}
            >
              For security reasons, we ask that you communicate with creators
              only through the Blitz platform. Violating this policy could lead
              to your account being terminated, and you would lose access to all
              your company's work and projects on Blitz. Thank you for
              understanding.
            </Alert>
          </Snackbar>
        </>
      )}
    </Box>
  );
};

export default CampaignCreatorSpecific;
