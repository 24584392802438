import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider, Tabs, Tab, TextField, Button, CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMutation } from 'react-query';
import client from '../../../../API';

const SidePanel = ({ selectedCreators, onRateChange, setSelectedCreators }) => {
  const [selectedPlatform, setSelectedPlatform] = useState('tiktok');
  const [campaignName, setCampaignName] = useState('');
  const [campaignBrief, setCampaignBrief] = useState('');
  const [emailRecipient, setEmailRecipient] = useState('');
  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [projectSum, setProjectSum] = useState(0);

  useEffect(() => {
    if (typeof onRateChange === 'function') {
      onRateChange(selectedPlatform);
    }
  
    const sum = selectedCreators.reduce((total, creator) => {
      const price = creator.price ? String(creator.price) : '0'; // Ensure price is a string
      const priceValue = parseFloat(price.replace(/[^0-9.-]+/g, '')) || 0;
      return total + priceValue;
    }, 0);
  
    setProjectSum(sum);
  }, [selectedPlatform, selectedCreators, onRateChange]);
  
  

  const handleTabChange = (event, newValue) => {
    setSelectedPlatform(newValue);
  };

  const handleRemoveCreator = (index) => {
    const updatedCreators = selectedCreators.filter((_, i) => i !== index);
    setSelectedCreators(updatedCreators);
  };

  const generateCampaignID = () => new Date().getTime().toString();

  const calculateTotalCampaignSum = () => {
    return selectedCreators.reduce((accumulator, creator) => {
      const priceAsString = creator.price || "0";
      const cleanedPriceString = priceAsString.replace(/[^0-9.-]+/g, "");
      const price = parseFloat(cleanedPriceString);
      return accumulator + (isFinite(price) ? price : 0);
    }, 0);
  };

  const createProjectData = () => {
    const newCreators = selectedCreators.map((creator) => {
      const price = parseFloat((creator.price || '0').replace(/[^0-9.-]+/g, "")) || 0; // Ensure price is a string
      return {
        id: creator.creator,
        name: creator.creator,
        price: price,
        following: creator.following,
        promotionPlatform: creator.promotionPlatform,
        promotionType: creator.promotionType,
        platformLink: creator.platformLink,
        pfphref: creator.pfphref,
      };
    });

    const projectData = {
      id: generateCampaignID(),
      name: campaignName,
      brief: campaignBrief,
      creators: newCreators,
      platform: selectedPlatform,
      description: campaignBrief,
      finish_date: new Date().toISOString().slice(0, 10),
      invoice_date: new Date().toISOString().slice(0, 10),
      paid_date: new Date().toISOString().slice(0, 10),
      status: "pending",
      po_number: generateCampaignID(),
      drive_link: "",
      blitz_autocampaign: false,
      shared_with: [],
      tools: {},
      proposalDate: new Date().toISOString().slice(0, 10),
      campaign_sum: calculateTotalCampaignSum(),
      idealDueDate: new Date().toISOString().slice(0, 10),
      emailRecipient: emailRecipient,
      amount: newCreators[0]?.price || 0,
      total_sum: calculateTotalCampaignSum(),
      agency: true
    };

    return projectData;
  };

  const { mutate: createCampaign, isLoading: isCreating } = useMutation(
    (newCampaign) => client.campaigns.createSimilar(newCampaign),
    {
      onSuccess: (data) => {
        setDialogMessage('Campaign created successfully!');
        setIsCreatingCampaign(false);
        setCampaignName('');
        setCampaignBrief('');
        setEmailRecipient('');
      },
      onError: (error) => {
        setDialogMessage(`Error: ${error.message}`);
        setIsCreatingCampaign(false);
      }
    }
  );

  const handleCreateCampaign = () => {
    if (!campaignName || !campaignBrief || !emailRecipient) {
      setDialogMessage('Please fill in all fields.');
      return;
    }

    setIsCreatingCampaign(true);

    const newCampaign = createProjectData();

    createCampaign(newCampaign);
  };

  if (!selectedCreators || selectedCreators.length === 0) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h6">No Creators Selected</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, width: '100%' }}>
      <Typography variant="h6">Selected Creators</Typography>
      <Divider sx={{ my: 2 }} />
      <Tabs
        value={selectedPlatform}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab value="instagram" label="Instagram" />
        <Tab value="tiktok" label="TikTok" />
      </Tabs>
      <Divider sx={{ my: 2 }} />
      {selectedCreators.map((creator, index) => (
        <Box key={index} sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="body1"><strong>Name:</strong> {creator.name}</Typography>
            <Typography variant="body1">
  <strong>Price:</strong> {creator.price ? `$${parseFloat(creator.price).toFixed(2)}` : "N/A"}
</Typography>
            <Typography variant="body1"><strong>Following:</strong> {creator.following}</Typography>
          </Box>
          
        </Box>
      ))}
      <Divider sx={{ my: 2 }} />
      <Typography variant="h6">Campaign Details</Typography>
      <TextField
        label="Campaign Name"
        variant="outlined"
        fullWidth
        value={campaignName}
        onChange={(e) => setCampaignName(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Brief"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={campaignBrief}
        onChange={(e) => setCampaignBrief(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        label="Email Recipient"
        variant="outlined"
        fullWidth
        value={emailRecipient}
        onChange={(e) => setEmailRecipient(e.target.value)}
        sx={{ mb: 2 }}
      />

      <Typography variant="body1" sx={{ mb: 2 }}>
      <Typography variant="body1" sx={{ mb: 2 }}>
  <strong>Project Sum:</strong> ${projectSum.toFixed(2)}
</Typography>      </Typography>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleCreateCampaign}
        disabled={isCreatingCampaign || isCreating}
      >
        {isCreatingCampaign || isCreating ? <CircularProgress size={24} /> : 'Create Campaign'}
      </Button>
      {dialogMessage && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {dialogMessage}
        </Typography>
      )}
    </Box>
  );
};

export default SidePanel;
