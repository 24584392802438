import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
  Table,
  TableBody,
  TableHead,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  CardActions,
  Grid,
  CircularProgress,
  Divider,
  Autocomplete,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import client from "../../../../API";
import useAuth from "../../../../Hooks/use-auth";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../../Utils/styledcell";
import ReplayIcon from "@mui/icons-material/Replay";

const Tools = ({ campaignDetails, onUpdate }) => {
  const { getCurrrentUser } = useAuth();
  const currentUser = getCurrrentUser();
  const isTcc = currentUser?.company_name === "TCC";

  const [email, setEmail] = useState("");
  const [bonusDialogOpen, setBonusDialogOpen] = useState(false);
  const [bonuses, setBonuses] = useState([]);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [bonusAmount, setBonusAmount] = useState("");
  const [cameThrough, setCameThrough] = useState(false);
  const [poNumber, setPoNumber] = useState(campaignDetails?.poNumber || "");
  const [managers, setManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState("");
  const [isChangingManager, setIsChangingManager] = useState(false);

  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [campaignHistory, setCampaignHistory] = useState([]);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);

  const [assignClientDialogOpen, setAssignClientDialogOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [newClientDetails, setNewClientDetails] = useState({
    full_name: "",
    company: "",
    email: "",
    location: "",
    phone_number: "",
  });

  const [assignedClients, setAssignedClients] = useState([]);

  const queryClient = useQueryClient();

  useEffect(() => {
    const bonuses =
      campaignDetails?.tools?.filter((tool) => tool.type === "bonus") || [];
    setBonuses(bonuses);
  }, [campaignDetails]);

  useEffect(() => {
    if (isTcc) {
      client.companies.listUsers().then(setManagers).catch(console.error);
    }
  }, [isTcc]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const res = await client.users.fetchClient();
        setClients(res);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };
    fetchClients();
  }, []);

  useEffect(() => {
    if (campaignDetails.id) {
      fetchAssignedClients();
    }
  }, [campaignDetails.id]);

  const fetchAssignedClients = async () => {
    try {
      const res = await client.campaigns.clientFetch(campaignDetails.id);
      setAssignedClients(res.clients || []);
    } catch (error) {
      console.error("Error fetching assigned clients:", error);
    }
  };

  const addBonus = useMutation(
    (newBonus) => client.campaigns.addBonus(campaignDetails.id, newBonus),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["campaign", campaignDetails.id]);
        alert("Bonus added successfully!");
        onUpdate();
      },
      onError: (error) => {
        alert(`Error adding bonus: ${error.message}`);
      },
    }
  );

  const changeManager = useMutation(client.campaigns.updateManager, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["campaign", campaignDetails.id]);
      alert("Manager changed successfully!");
      onUpdate();
    },
    onError: (error) => {
      alert(`Error changing manager: ${error.message}`);
    },
  });

  // Updated assignClient function
const assignClient = async (clientId) => {
  try {
    await client.campaigns.client(campaignDetails.id, { client_id: clientId });
    alert("Client assigned successfully!");
    fetchAssignedClients();
    onUpdate();
  } catch (error) {
    console.error("Error assigning client:", error);
    alert("There was an error assigning the client.");
  }
};


  // Updated addAndAssignClient function
const addAndAssignClient = async () => {
  try {
    const newClientData = {
      client: newClientDetails,
    };
    await client.campaigns.client(campaignDetails.id, newClientData);
    alert("New client created and assigned successfully!");
    fetchAssignedClients();
    setAssignClientDialogOpen(false);
    setNewClientDetails({
      full_name: "",
      company: "",
      email: "",
      location: "",
      phone_number: "",
    });
  } catch (error) {
    console.error("Error adding and assigning client:", error);
    alert("There was an error adding and assigning the client.");
  }
};


  const handleBonusSubmit = () => {
    addBonus.mutate({
      creator_id: selectedCreator,
      bonusAmount,
      cameThrough,
    });
    setBonusDialogOpen(false);
    resetBonusForm();
  };

  const resetBonusForm = () => {
    setBonusAmount("");
    setSelectedCreator(null);
    setCameThrough(false);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleShare = () => {
    if (email) {
      alert("Campaign shared!");
    } else {
      alert("Please enter an email address.");
    }
  };

  const handlePoNumberChange = (event) => {
    setPoNumber(event.target.value);
    onUpdate({ ...campaignDetails, poNumber: event.target.value });
  };

  const handleManagerChange = (event) => {
    setSelectedManager(event.target.value);
    setIsChangingManager(true);
  };

  const applyManagerChange = () => {
    const managerIndex = Number.parseInt(selectedManager);
    changeManager.mutate({
      campaignId: campaignDetails.id,
      manager: {
        name: `${managers[managerIndex].first_name} ${managers[managerIndex].last_name}`,
        email: managers[managerIndex].email,
        phone_number: "",
      },
    });
    setIsChangingManager(false);
  };

  const handleHistoryOpen = () => {
    setIsLoadingHistory(true);
    client.campaigns
      .history(campaignDetails.id)
      .then((data) => {
        setCampaignHistory(data);
        setHistoryDialogOpen(true);
      })
      .catch((error) => {
        alert(`Error fetching history: ${error.message}`);
      })
      .finally(() => {
        setIsLoadingHistory(false);
      });
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleHistoryOpen}
                  >
                    <ReplayIcon sx={{ marginRight: 1 }} />
                    <Typography variant="h6">View Campaign History</Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card>
                <CardContent>
                  <Typography variant="h6">Share this Campaign</Typography>
                  <TextField
                    label="Email Address"
                    type="email"
                    fullWidth
                    value={email}
                    onChange={handleEmailChange}
                    variant="outlined"
                    margin="normal"
                  />
                  <Button variant="contained" onClick={handleShare} fullWidth>
                    Share Campaign
                  </Button>
                  {campaignDetails?.shared_with && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="h6">Shared With:</Typography>
                      <Typography>{campaignDetails.shared_with.join(", ")}</Typography>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
            {/* Other sections below the "Shared With" section */}
            <Grid item>              <Card>
                <CardContent>
                  <Typography variant="h6">PO Number</Typography>
                  <TextField
                    label="PO Number"
                    fullWidth
                    value={poNumber}
                    onChange={handlePoNumberChange}
                    variant="outlined"
                    margin="normal"
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6">Bonuses</Typography>
                    <Button
                      variant="contained"
                      onClick={() => setBonusDialogOpen(true)}
                    >
                      Add Bonus
                    </Button>
                  </Box>
                  <Table size="small" sx={{ mt: 2 }}>
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell>Creator Name</StyledTableCell>
                        <StyledTableCell>Bonus Amount</StyledTableCell>
                        <StyledTableCell>Came Through</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {bonuses?.map((bonus, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell>
                            {
                              campaignDetails?.creators?.find(
                                (c) => c.id === bonus.creator_id
                              )?.name
                            }
                          </StyledTableCell>
                          <StyledTableCell>{bonus.bonusAmount}</StyledTableCell>
                          <StyledTableCell>
                            {bonus.cameThrough ? "Yes" : "No"}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">Assign Client</Typography>
                <Button
                  variant="contained"
                  onClick={() => setAssignClientDialogOpen(true)}
                >
                  Assign Client
                </Button>
              </Box>
              {assignedClients.length > 0 ? (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body1">
                    <strong>Assigned Clients:</strong>
                  </Typography>
                  {assignedClients.map((client) => (
                    <Box key={client.client_id} sx={{ mt: 1 }}>
                      <Typography variant="body2">
                        <strong>Name:</strong> {client.full_name}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Company:</strong> {client.company}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Email:</strong> {client.email}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Phone:</strong> {client.phone_number}
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                    </Box>
                  ))}
                </Box>
              ) : (
                <Typography variant="body1" sx={{ mt: 2 }}>
                  No clients assigned to this campaign.
                </Typography>
              )}
            </CardContent>
          </Card>

          {isTcc && (
            <Card sx={{ mt: 2 }}>
              <CardContent>
                <Typography variant="h6">Change Campaign Manager</Typography>
                <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                  <InputLabel>Select Manager</InputLabel>
                  <Select
                    value={selectedManager}
                    onChange={handleManagerChange}
                    label="Select Manager"
                  >
                    {managers.map((manager, index) => (
                      <MenuItem key={manager.id} value={index}>
                        {manager.first_name} {manager.last_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  sx={{ mt: 2 }}
                >
                  Current Manager
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 1,
                  }}
                >
                  <Typography variant="body2">
                    <strong>Name:</strong> {campaignDetails.campaign_manager?.name}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Email:</strong> {campaignDetails.campaign_manager?.email}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Phone:</strong>{" "}
                    {campaignDetails.campaign_manager?.phone_number}
                  </Typography>
                </Box>
              </CardContent>
              {isChangingManager && (
                <CardActions>
                  <Button
                    variant="contained"
                    onClick={applyManagerChange}
                    fullWidth
                  >
                    Apply Manager Change
                  </Button>
                </CardActions>
              )}
            </Card>
          )}
        </Grid>
      </Grid>

      <Dialog
        open={assignClientDialogOpen}
        onClose={() => setAssignClientDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Assign Client</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <Autocomplete
              options={clients}
              getOptionLabel={(option) => option.full_name || option.email || ""}
              value={selectedClient}
              onChange={(event, newValue) => {
                setSelectedClient(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Existing Client"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
          <Typography variant="body1" align="center">
            Or
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">Add New Client</Typography>
          <TextField
            name="full_name"
            label="Full Name"
            value={newClientDetails.full_name}
            onChange={(e) =>
              setNewClientDetails({
                ...newClientDetails,
                full_name: e.target.value,
              })
            }
            margin="normal"
            fullWidth
          />
          <TextField
            name="company"
            label="Company"
            value={newClientDetails.company}
            onChange={(e) =>
              setNewClientDetails({
                ...newClientDetails,
                company: e.target.value,
              })
            }
            margin="normal"
            fullWidth
          />
          <TextField
            name="email"
            label="Email"
            value={newClientDetails.email}
            onChange={(e) =>
              setNewClientDetails({
                ...newClientDetails,
                email: e.target.value,
              })
            }
            margin="normal"
            fullWidth
          />
          <TextField
            name="location"
            label="Location"
            value={newClientDetails.location}
            onChange={(e) =>
              setNewClientDetails({
                ...newClientDetails,
                location: e.target.value,
              })
            }
            margin="normal"
            fullWidth
          />
          <TextField
            name="phone_number"
            label="Phone Number"
            value={newClientDetails.phone_number}
            onChange={(e) =>
              setNewClientDetails({
                ...newClientDetails,
                phone_number: e.target.value,
              })
            }
            margin="normal"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAssignClientDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (selectedClient) {
                assignClient(selectedClient.id);
                setAssignClientDialogOpen(false);
              } else {
                addAndAssignClient();
              }
            }}
          >
            Assign Client
          </Button>
        </DialogActions>
      </Dialog>

      {bonusDialogOpen && (
        <Dialog
          open={bonusDialogOpen}
          onClose={() => setBonusDialogOpen(false)}
        >
          <DialogTitle>Assign Bonus</DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Select a creator</InputLabel>
              <Select
                value={selectedCreator}
                onChange={(e) => setSelectedCreator(e.target.value)}
                displayEmpty
                fullWidth
                sx={{ mt: 2 }}
              >
                {campaignDetails?.creators?.map((creator) => (
                  <MenuItem key={creator.id} value={creator.id}>
                    {creator.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Bonus Amount ($)"
              type="number"
              value={bonusAmount}
              onChange={(e) => setBonusAmount(e.target.value)}
              sx={{
                mt: 2,
                mb: 2,
              }}
            />
            <Box
              sx={{ display: "flex", alignItems: "center", mt: 2, mb: 2 }}
            >
              <Checkbox
                checked={cameThrough}
                onChange={(e) => setCameThrough(e.target.checked)}
              />
              <Typography>Came Through</Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setBonusDialogOpen(false)}>Close</Button>
            <Button variant="contained" onClick={handleBonusSubmit}>
              Add Bonus
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {historyDialogOpen && (
        <Dialog
          open={historyDialogOpen}
          onClose={() => setHistoryDialogOpen(false)}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Campaign History</DialogTitle>
          <DialogContent dividers>
            {isLoadingHistory ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                {campaignHistory.map((historyItem) => {
                  let changes = historyItem.changes;
                  if (typeof changes === "string") {
                    try {
                      changes = JSON.parse(changes);
                    } catch (error) {
                      console.error("Error parsing changes:", error);
                      changes = {};
                    }
                  }

                  const flattenObject = (obj, parentKey = "", result = {}) => {
                    for (let key in obj) {
                      const propName = parentKey
                        ? `${parentKey}.${key}`
                        : key;
                      if (
                        typeof obj[key] === "object" &&
                        obj[key] !== null
                      ) {
                        flattenObject(obj[key], propName, result);
                      } else {
                        result[propName] = obj[key];
                      }
                    }
                    return result;
                  };

                  const flattenedChanges = flattenObject(changes);

                  return (
                    <Box
                      key={historyItem.id}
                      sx={{
                        mb: 3,
                        p: 2,
                        border: "1px solid #ccc",
                        borderRadius: 4,
                      }}
                    >
                      <Typography variant="subtitle1">
                        <strong>User:</strong> {historyItem.user_name}
                      </Typography>
                      <Typography variant="subtitle1">
                        <strong>Timestamp:</strong>{" "}
                        {new Date(historyItem.timestamp).toLocaleString()}
                      </Typography>
                      <Divider sx={{ my: 2 }} />
                      <Typography variant="subtitle2">Changes:</Typography>
                      <Box sx={{ mt: 1 }}>
                        {Object.entries(flattenedChanges).map(
                          ([fieldName, change]) => {
                            let oldValue = "";
                            let newValue = "";

                            if (
                              change &&
                              typeof change === "object" &&
                              "old_value" in change &&
                              "new_value" in change
                            ) {
                              ({
                                old_value: oldValue,
                                new_value: newValue,
                              } = change);
                            } else {
                              oldValue = "N/A";
                              newValue = change;
                            }

                            return (
                              <Box
                                key={fieldName}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  mb: 2,
                                }}
                              >
                                <Typography variant="body1">
                                  <strong>{fieldName}</strong>
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    ml: 2,
                                    mt: 1,
                                  }}
                                >
                                  <Typography sx={{ width: "50%" }}>
                                    <strong>Old:</strong>{" "}
                                    {oldValue !== null
                                      ? oldValue.toString()
                                      : "null"}
                                  </Typography>
                                  <Typography sx={{ width: "50%" }}>
                                    <strong>New:</strong>{" "}
                                    {newValue !== null
                                      ? newValue.toString()
                                      : "null"}
                                  </Typography>
                                </Box>
                                <Divider sx={{ mt: 1 }} />
                              </Box>
                            );
                          }
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setHistoryDialogOpen(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default Tools;
