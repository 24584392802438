import React, { useState, useEffect } from "react";
import {
    Avatar,
    Button,
    TextField,
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    Paper,
    Typography,
    Box,
    Backdrop,
    CircularProgress,
    Link,
} from "@mui/material";
import { PhotoCamera } from '@mui/icons-material';
import API from "../../../API";
import useAlertDialog from "../../../Components/useAlertDialog";
import AlertDialog from "../../../Components/AlertDialog";
import { RateField } from "../../../Components/RateField";
import MuiPhoneInput from "../../../Components/MuiPhoneInput";
import UploadFileDialogPFP from "../../../CreatorUser/onboardupload";
import CheckCircle from "@mui/icons-material/CheckCircle";
import {Link as RouterLink} from 'react-router-dom';
import routes from "../../../Config/routes";
import { ethnicity, genders, niches, parseLocalFloat, parseLocalInt, region } from "../../../Utils/constants";

export default function AgencyCreatorEdit({ creatorData }) {
    const { dialogState, openDialog, closeDialog } = useAlertDialog();

    const [contactEmail, setContactEmail] = useState('');
    const [paymentEmail, setPaymentEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [creatorName, setCreatorName] = useState('');
    const [creatorRegion, setCreatorRegion] = useState('');
    const [platforms, setPlatforms] = useState({
        TikTok: false,
        Instagram: false,
        YouTube: false,
    });
    const [primaryMarket, setPrimaryMarket] = useState('');
    const [notesContentStyle, setNotesContentStyle] = useState('');
    const [profilePhotoUrl, setProfilePhotoUrl] = useState('');
    const [race, setRace] = useState('');
    const [gender, setGender] = useState('');
    const [location, setLocation] = useState('');

    const [instagramLink, setInstagramLink] = useState("");
    const [instagramBrandRate, setInstagramBrandRate] = useState('');
    const [instagramFollowerCount, setInstagramFollowerCount] = useState('');
    const [instagramSongRate, setInstagramSongRate] = useState('');
    const [instagramStoryRate, setInstagramStoryRate] = useState('');

    const [tikTokLink, setTikTokLink] = useState("");
    const [tikTokFollowerCount, setTikTokFollowerCount] = useState('');
    const [tikTokBrandRate, setTikTokBrandRate] = useState('');
    const [tikTokSongRate, setTikTokSongRate] = useState('');

    const [youtubeLink, setYoutubeLink] = useState("");
    const [youtube30sBrandRate, setYoutube30sBrandRate] = useState('');
    const [youtubeFollowerCount, setYoutubeFollowerCount] = useState('');
    const [youtube60sBrandRate, setYoutube60sBrandRate] = useState('');
    const [youtubeShortsBrandRate, setYoutubeShortsBrandRate] = useState('');

    const [userStatus, setUserStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [validationError, setValidationError] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("PayPal");
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

    useEffect(() => {
        if (creatorData) {
            GetUserData();
        }
    }, [creatorData])

    function GetUserData() {

        const user = creatorData.creator_user;

        const creator = creatorData.creator;

        setUserStatus(creator?.status ? creator.status : (user?.refresh_token ? 'Partner' : 'Associate'));
        setPaymentMethod(user?.payout_preferred || 'PayPal');


        setContactEmail(creator.email);
        setPaymentEmail(user?.email || creator.payout_email || '');
        setPhoneNumber(creator.phone_number);
        setCreatorName(creator.creator);
        setCreatorRegion(creator.region);
        setPlatforms({
            TikTok: creator.tiktok_link !== null,
            Instagram: creator.instagram_link !== null,
            YouTube: creator.youtube_link !== null,
        });
        setPrimaryMarket(creator.primary_market);
        setNotesContentStyle(creator.notes_content_style);
        setRace(creator.ethnicity);
        setGender(creator.gender);
        setLocation(creator.geolocation);
        setProfilePhotoUrl(creator.pfphref);

        setInstagramLink(creator.instagram_link ? creator.instagram_link.replace("https://www.instagram.com/", "") : "");
        setInstagramFollowerCount(creator.instagram ? formatNumber(creator.instagram) : "");

        setInstagramBrandRate(creator.rate_ig_reelbrand?.toFixed(2) || "");
        setInstagramSongRate(creator.rate_ig_reelsound?.toFixed(2) || "");
        setInstagramStoryRate(creator.rate_ig_feedpost?.toFixed(2) || "");

        setTikTokLink(creator.tiktok_link ? creator.tiktok_link.replace("https://www.tiktok.com/@", "") : "");
        setTikTokFollowerCount(creator.tiktok ? formatNumber(creator.tiktok) : "");

        setTikTokBrandRate(creator.rate_tt_brand?.toFixed(2) || "");
        setTikTokSongRate(creator.rate_tt_sound?.toFixed(2) || "");

        setYoutubeLink(creator.youtube_link ? creator.youtube_link.replace("https://www.youtube.com/", "") : "");
        setYoutubeFollowerCount(creator.youtube ? formatNumber(creator.youtube) : "");

        setYoutube30sBrandRate(creator.rate_yt_integ30s?.toFixed(2) || "");
        setYoutube60sBrandRate(creator.rate_yt_integ60s?.toFixed(2) || "");
        setYoutubeShortsBrandRate(creator.rate_yt_short?.toFixed(2) || "");


    }

    const handleOpenUploadDialog = () => {
        setIsUploadDialogOpen(true);
    };

    const handleCloseUploadDialog = (uploadedUrl) => {
        console.log(uploadedUrl)
        if (uploadedUrl) {
            setProfilePhotoUrl(uploadedUrl);
        }
        setIsUploadDialogOpen(false);
    };

    const handlePlatformChange = (event) => {
        setPlatforms({ ...platforms, [event.target.name]: event.target.checked });
    };

    const handleFollowerCountChange = (setter) => (e) => {
        const rawValue = e.target.value.replace(/[^0-9]/g, "");
        const formattedValue = formatNumber(rawValue);
        setter(formattedValue);
    };

    const formatNumber = (value) => {
        const number = parseInt(value, 10);
        return isNaN(number) ? "0" : number.toString();
    };

    const handleStartSaveCreator = () => {
        handleSaveCreator();
    };

    const handleSaveCreator = async () => {

        if (phoneNumber.length < 11) {
            alert(`This Phone number is not valid`);
            return;
        }

        if (creatorName.includes(" ")) {
            setValidationError("Creator name cannot contain spaces.");
            return;
        }

        const anyPlatform = [platforms.TikTok, platforms.Instagram, platforms.YouTube].some((value) => value);
        if (!anyPlatform) {
            alert('You need to select at least one platform.');
            return;
        }

        if (platforms.TikTok) {
            const anyPromo = [tikTokBrandRate, tikTokSongRate].some((value) => value);
            if (!anyPromo) {
                alert('You need to select at least one promotion type for TikTok.');
                return;
            }
        }

        if (platforms.Instagram) {
            const anyPromo = [instagramBrandRate, instagramSongRate, instagramStoryRate].some((value) => value);
            if (!anyPromo) {
                alert('You need to select at least one promotion type for Instagram.');
                return;
            }
        }

        if (platforms.YouTube) {
            const anyPromo = [youtube30sBrandRate, youtube60sBrandRate, youtubeShortsBrandRate].some((value) => value);
            if (!anyPromo) {
                alert('You need to select at least one promotion type for YouTube.');
                return;
            }
        }

        setIsLoading(true);

        const formattedTikTokLink = tikTokLink ? `https://www.tiktok.com/@${tikTokLink}` : null;
        const formattedInstagramLink = instagramLink ? `https://www.instagram.com/${instagramLink}` : null;
        const formattedYouTubeLink = youtubeLink ? `https://www.youtube.com/${youtubeLink}` : null;

        let payload = null

        try {
            payload = {
                tiktok: parseLocalInt(tikTokFollowerCount) || null,
                tiktok_link: formattedTikTokLink || null,
                instagram: parseLocalInt(instagramFollowerCount) || null,
                instagram_link: formattedInstagramLink || null,
                youtube: parseLocalInt(youtubeFollowerCount) || null,
                youtube_link: formattedYouTubeLink || null,
                geolocation: location,
                gender: gender,
                ethnicity: race,
                primary_market: primaryMarket,
                region: creatorRegion,
                notescontent_style: notesContentStyle || null,

                rate_tt_sound: parseLocalInt(tikTokSongRate),
                rate_tt_brand: parseLocalFloat(tikTokBrandRate),
                rate_ig_feedpost: parseLocalFloat(instagramStoryRate),
                rate_ig_reelsound: parseLocalFloat(instagramSongRate),
                rate_ig_reelbrand: parseLocalFloat(instagramBrandRate),
                rate_yt_integ30s: parseLocalFloat(youtube30sBrandRate),
                rate_yt_integ60s: parseLocalFloat(youtube60sBrandRate),
                rate_yt_short: parseLocalFloat(youtubeShortsBrandRate),

                contactEmail: contactEmail,
                paymentEmail: paymentEmail,
                phone_number: phoneNumber,
                pfphref: profilePhotoUrl,
            };
        } catch (error) {
            alert(`Error parsing this form: ${error}`);
            setIsLoading(false);
            return;
        }


        try {
            await API.agency.editCreator(creatorData.creator.creator, payload);
            openDialog("Success", "Creator data edited successfully", closeDialog, closeDialog, "Ok", null);
        } catch (error) {
            openDialog("Error", `Network error: ${error.response.data && error.response.data.error ? error.response.data.error : error}`, closeDialog, closeDialog, "Ok", null);
            console.error('Network error:', error);
        }
        setIsLoading(false);
    };

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <AlertDialog alertState={dialogState}></AlertDialog>
            <Box sx={{ paddingInline: 4, paddingBlockStart: 2, paddingBlockEnd: 4, marginBlockStart: 2, maxWidth: '50em', marginInline: { xs: "auto", md: 0 } }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
                    <Avatar
                        alt={creatorName}
                        src={profilePhotoUrl}
                        sx={{ width: 100, height: 100, cursor: 'pointer' }}
                        onClick={handleOpenUploadDialog}
                    >
                        {!profilePhotoUrl && <PhotoCamera fontSize="large" />}
                    </Avatar>
                </Box>
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                    {!!creatorData?.creator_user ? 'This creator is verified' : 'This creator is not verified'} 
                    <CheckCircle sx={{ marginLeft: '8px', color: !!creatorData?.creator_user ? 'success.main' : 'action.disabled' }} />
                </Typography>
                {!creatorData?.creator_user && <Link variant="subtitle2" component={RouterLink} to={routes.creatorConnect.replace(':creator', creatorData?.creator?.creator)}>
                Send your creator this link to verify them through CreatorConnect!
                </Link>}

                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleStartSaveCreator();
                    }}
                >
                    <Typography variant="h5" sx={{ marginBlockEnd: 2, marginBlockStart: 1 }}>Payment Information</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: "end" }}>
                        <FormControl fullWidth sx={{ flex: 1 }} margin="dense" disabled>
                            <InputLabel>Payment Method</InputLabel>
                            <Select value={paymentMethod} label='Payment Method'>
                                <MenuItem value="PayPal">PayPal, Standard, Fees Apply</MenuItem>
                                <MenuItem value="Stripe">BlitzPay, Faster, Less Fees</MenuItem>
                            </Select>
                        </FormControl>
                        {<TextField
                            margin="dense"
                            label="Paypal Payment Email"
                            type="email"
                            disabled={paymentMethod != 'PayPal'}
                            fullWidth
                            sx={{ flex: 1 }}
                            value={paymentEmail}
                            onChange={(e) => setPaymentEmail(e.target.value)}
                            variant="outlined"
                            required
                        />}
                    </Box>
                    <Typography variant="h5" sx={{ marginBlockEnd: 2, marginBlockStart: 1 }}>Creator information</Typography>
                    <TextField
                        margin="dense"
                        label="Creator Name"
                        type="text"
                        fullWidth
                        value={creatorName}
                        onChange={(e) => setCreatorName(e.target.value)}
                        variant="outlined"
                        required
                        error={!!validationError}
                        helperText={validationError}
                        disabled
                    />
                    <FormControl variant="outlined" fullWidth margin="dense" required>
                        <InputLabel>Creator Region</InputLabel>
                        <Select
                            label="Creator Region"
                            value={creatorRegion}
                            onChange={(e) => setCreatorRegion(e.target.value)}
                        >
                            {region.map((value)=>(<MenuItem key={value} value={value}>{value}</MenuItem>))}
                        </Select>
                    </FormControl>
                    <FormGroup row>
                        {Object.keys(platforms).map((platform) => (
                            <FormControlLabel
                                key={platform}
                                control={
                                    <Checkbox
                                        checked={platforms[platform]}
                                        onChange={handlePlatformChange}
                                        name={platform}
                                    />
                                }
                                label={platform}
                            />
                        ))}
                    </FormGroup>
                    {platforms.Instagram && (
                        <>
                            <TextField
                                margin="dense"
                                label="Instagram Username"
                                type="text"
                                fullWidth
                                value={instagramLink}
                                onChange={(e) => setInstagramLink(e.target.value)}
                                variant="outlined"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            instagram.com/
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                margin="dense"
                                label="Instagram Follower Count"
                                type="text"
                                fullWidth
                                value={instagramFollowerCount}
                                onChange={handleFollowerCountChange(setInstagramFollowerCount)}
                                variant="outlined"
                                required
                            />
                            <RateField
                                margin="dense"
                                label="Instagram Brand Rate"
                                fullWidth
                                value={instagramBrandRate}
                                onChange={(value) => setInstagramBrandRate(value)}
                                variant="outlined"
                                minValue={100}
                            />
                            <RateField
                                margin="dense"
                                label="Instagram Song Rate"
                                fullWidth
                                value={instagramSongRate}
                                onChange={(value) => setInstagramSongRate(value)}
                                variant="outlined"
                                minValue={100}
                            />
                            <RateField
                                margin="dense"
                                label="Instagram Story Rate"
                                fullWidth
                                value={instagramStoryRate}
                                onChange={(value) => setInstagramStoryRate(value)}
                                variant="outlined"
                                minValue={20}
                            />
                        </>
                    )}
                    {platforms.TikTok && (
                        <>
                            <TextField
                                margin="dense"
                                label="TikTok Username"
                                type="text"
                                fullWidth
                                value={tikTokLink}
                                onChange={(e) => setTikTokLink(e.target.value)}
                                variant="outlined"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            tiktok.com/@
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                margin="dense"
                                label="TikTok Follower Count"
                                type='number'
                                fullWidth
                                value={tikTokFollowerCount}
                                onChange={handleFollowerCountChange(setTikTokFollowerCount)}
                                variant="outlined"
                                required
                            />
                            <RateField
                                margin="dense"
                                label="TikTok Brand Rate"
                                fullWidth
                                value={tikTokBrandRate}
                                onChange={(value) => setTikTokBrandRate(value)}
                                variant="outlined"
                                minValue={100}
                            />
                            <RateField
                                margin="dense"
                                label="TikTok Song Rate"
                                fullWidth
                                value={tikTokSongRate}
                                onChange={(value) => setTikTokSongRate(value)}
                                variant="outlined"
                                minValue={100}
                            />
                        </>
                    )}
                    {platforms.YouTube && (
                        <>
                            <TextField
                                margin="dense"
                                label="Youtube Username"
                                type="text"
                                fullWidth
                                value={youtubeLink}
                                onChange={(e) => setYoutubeLink(e.target.value)}
                                variant="outlined"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            youtube.com/
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                margin="dense"
                                label="YouTube Follower Count"
                                type='number'
                                fullWidth
                                value={youtubeFollowerCount}
                                onChange={handleFollowerCountChange(setYoutubeFollowerCount)}
                                variant="outlined"
                                required
                            />
                            <RateField
                                margin="dense"
                                label="YouTube Brand Rate - 30s"
                                fullWidth
                                value={youtube30sBrandRate}
                                onChange={(value) => setYoutube30sBrandRate(value)}
                                variant="outlined"
                                minValue={100}
                            />
                            <RateField
                                margin="dense"
                                label="YouTube Brand Rate - 60s"
                                fullWidth
                                value={youtube60sBrandRate}
                                onChange={(value) => setYoutube60sBrandRate(value)}
                                variant="outlined"
                                minValue={100}
                            />
                            <RateField
                                margin="dense"
                                label="YouTube Shorts Rate"
                                fullWidth
                                value={youtubeShortsBrandRate}
                                onChange={(value) => setYoutubeShortsBrandRate(value)}
                                variant="outlined"
                                minValue={100}
                            />
                        </>
                    )}

                    <FormControl variant="outlined" fullWidth margin="dense">
                        <InputLabel id="race-select-label">Race (optional)</InputLabel>
                        <Select
                            labelId="race-select-label"
                            value={race}
                            onChange={(e) => setRace(e.target.value)}
                            label="Race (optional)"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {ethnicity.map((value)=>(<MenuItem key={value} value={value}>{value}</MenuItem>))}
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" fullWidth margin="dense" required>
                        <InputLabel id="gender-select-label">Gender</InputLabel>
                        <Select
                            labelId="gender-select-label"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            label="Gender"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {genders.map((value)=>(<MenuItem key={value} value={value}>{value}</MenuItem>))}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Location"
                        type="text"
                        fullWidth
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        variant="outlined"
                        required
                    />
                    <FormControl variant="outlined" fullWidth margin="dense" required>
                        <InputLabel id="primary-market-label">Primary Market</InputLabel>
                        <Select
                            labelId="primary-market-label"
                            value={primaryMarket}
                            onChange={(e) => setPrimaryMarket(e.target.value)}
                            label="Primary Market"
                        >
                            {niches.map((market) => (
                                <MenuItem key={market} value={market}>
                                    {market}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Bio"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        placeholder="Tell us about yourself"
                        variant="outlined"
                        value={notesContentStyle}
                        onChange={(e) => setNotesContentStyle(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Contact Email"
                        type="email"
                        fullWidth
                        value={contactEmail}
                        onChange={(e) => setContactEmail(e.target.value)}
                        variant="outlined"
                        required
                    />
                    <MuiPhoneInput
                        country={'us'}
                        preferredCountries={['us']}
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        isValid={(value) => value.length >= 10}
                        required
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                        sx={{ marginTop: 1, marginBottom: 2 }}
                    >
                        Save Changes
                    </Button>
                </form>
            </Box>
            <UploadFileDialogPFP open={isUploadDialogOpen} onClose={handleCloseUploadDialog} />
        </>
    );
}
